<template>
  <div class="payment-transactions">
    <h1>
      Subscribers' Payment Transactions <small v-if="total">({{ total }})</small>
    </h1>
    <button class="export-btn" @click="exportExcel()">Export in Excel</button>


    <!-- Master Filter -->
    <MasterFilter
        :filters="filters"
        :filtersDisplayed="filtersDisplayed"
        :activeFilterIndex="activeFilterIndex"
        @apply-filters="applyFilters"
        @reset-filters="resetFilters"
    />


    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :actions="[]"
      :updateResults="updateResults"
      @update-table="getSubscribersPaymentsHandler"
    />
  </div>
</template>

<script>
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import usersMixin from "@/mixins/users";
import userMixin from "@/mixins/user";
import { utils, writeFile } from "xlsx";
import MasterFilter from "@/components/shared/filters/MasterFilter.vue";

export default {
  name: "SubscribersPayments",
  mixins: [usersMixin, userMixin],
  components: {
    DataTableHolder,
    MasterFilter,
  },
  data() {
    return {
      rows: null,
      columns: [
        { title: "Payment Log ID", name: "paymentLogId", sortable: true },
        { title: "Subscription ID", name: "subscriptionId", sortable: true },
        { title: "Date", name: "operationDate", sortable: true },
        { title: "Last Name", name: "lastName", sortable: true },
        { title: "First Name", name: "firstName", sortable: true },
        { title: "Email", name: "email", sortable: false },
        { title: "Paid Plan", name: "paidPlan", sortable: false },
        { title: "Billing Period", name: "billingPeriod", sortable: false },
        { title: "Net Amount", name: "amountExVAT", sortable: false },
        { title: "Gross Amount", name: "amountIncVAT", sortable: false },
        { title: "VAT Amount", name: "amountVAT", sortable: false },
        { title: "VAT Rate", name: "vatRate", sortable: false },
        { title: "Currency", name: "currencyCode", sortable: false },
        { title: "Payrexx Fee", name: "payrexxFee", sortable: false },
        { title: "Card Brand", name: "cardBrand", sortable: false },
        { title: "Card Last Digits", name: "cardNumber", sortable: false },
        { title: "Card Expiry Date", name: "cardExpiryDate", sortable: false },
        { title: "Country of Domicile", name: "countryOfDomicile", sortable: false },
        { title: "Operation Type", name: "operation", sortable: false },
        { title: "Promo Code", name: "promoCode", sortable: false },
      ],
      pages: 0,
      total: null,
      fullData: null,
      fromDate: null,
      toDate: null,
      updateResults: false,
      countryOfDomicile: [],
      operation: [],
      filters: {
        dropdowns: {
          countryOfDomicile: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 216, name: "Switzerland (CH)" },
              { id: 83, name: "Germany (DE)" },
              { id: 15, name: "Austria (AT)" },
              { id: 235, name: "United Kingdom (GB)" },
              { id: 157, name: "Netherlands (NL)" },
              { id: 209, name: "Spain (ES)" }
            ],
            label: 'name',
            placeholder: "Select Countries",
            multiselect: true,
            tableName: 'countryOfDomicile'
          },
          operation: {
            selectedOptions: [],
            dropdownOptions: [
              { id: "payment", name: "Payment" },
              { id: "invoice-payment", name: "Invoice Payment" },
              { id: "membership-payment", name: "Membership Payment"},
              // { id: "webinar-payment", name: "Webinar Payment"},
              { id: "declined", name: "Declined" },
              { id: "refunded", name: "Refunded" },
              { id: "auth-success", name: "Auth Success" },
              { id: "pre-authorization", name: "Pre-authorization" },
              { id: "webhook", name: "Webhook" },
              { id: "expired", name: "Expired" },
              { id: "cancelled", name: "Cancelled" },
              { id: "unknown", name: "Unknown" }
            ],
            label: 'name',
            placeholder: "Select Operations",
            multiselect: true,
            tableName: 'operation'
          },
          billingPeriod: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 0, name: "Monthly" },
              { id: 1, name: "Anually" },
            ],
            label: 'name',
            placeholder: "Select Period",
            multiselect: true,
            tableName: 'billingPeriod'
          },
          paidPlan: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 0, name: "Explore" },
              { id: 1, name: "Learn" },
              { id: 2, name: "Grow" }
            ],
            label: 'name',
            placeholder: "Select Paid Plan",
            multiselect: true,
            tableName: 'paidPlan'
          },
          dateRange: {
            selectedOptions: [this.fromDate, this.toDate], // Initial date range values
            label: 'Date Range',
            placeholder: 'Select Date Range',
            type: 'dateRange', // Custom type for range picker in MasterFilter
          },
        }
      },
      filtersDisplayed: true,
      activeFilterIndex: -1,
      filtersToSend: {},
    };
  },
  created() {
    this.fromDate = this.startOfMonth();
    this.toDate = this.today();
    this.filters.dropdowns.dateRange.selectedOptions = [this.fromDate, this.toDate];

    this.applyFilters();
  },
  watch: {
    fromDate() {
      this.updateResults = !this.updateResults;
    },
    toDate() {
      this.updateResults = !this.updateResults;
    },
    operation() {
      this.updateResults = !this.updateResults;
    },
  },

  methods: {

    applyFilters() {
      const dropdowns = this.filters.dropdowns;
      this.filtersToSend = {};

      // Collect dropdown selections
      for (const key in dropdowns) {
        const selectedOptions = dropdowns[key].selectedOptions;

        if (selectedOptions && selectedOptions.length > 0) {
          this.filtersToSend[key] = selectedOptions.map(option => option.id);
        }
        else if (key === "dateRange" && selectedOptions) {
          this.filtersToSend.fromDate = selectedOptions[0];
          this.filtersToSend.toDate = selectedOptions[1];
        }
      }

      this.updateResults = !this.updateResults;
    },

    resetFilters() {
      for (const key in this.filters.dropdowns) {
        this.filters.dropdowns[key].selectedOptions = key === 'dateRange' ? [this.startOfMonth(), this.today()] : [];
      }

      this.applyFilters();
    },

    getSubscribersPaymentsHandler(data) {

      this.setFilters(data);

      this.getSubscribersPayments(data)
        .then((result) => {
          this.pages = result.pagesCount;
          let paymentsFormatted = [];
          for (const payment of result.userRows) {
            let paymentObject = {
              paymentLogId: payment.paymentLogId,
              subscriptionId: payment.subscriptionId,
              operationDate: payment.operationDate,
              lastName: payment.lastName,
              firstName: payment.firstName,
              email: payment.email,
              paidPlan: this.convertToPlan(payment.paidPlan),
              billingPeriod: this.convertToPeriod(payment.billingPeriod),
              amountExVAT: this.formatDecimals(payment.amountExVAT),
              amountIncVAT: this.formatDecimals(payment.amountIncVAT),
              amountVAT: this.formatDecimals(payment.amountVAT),
              vatRate: payment.vatRate,
              currencyCode: payment.currencyCode,
              payrexxFee: payment.payrexxFee,
              cardBrand: payment.cardBrand,
              cardNumber: payment.cardNumber,
              cardExpiryDate: payment.cardExpiryDate,
              countryOfDomicile: payment.countryOfDomicile,
              operation: payment.operation,
              promoCode: payment.promoCode,
            };
            paymentsFormatted.push(paymentObject);
          }
          this.rows = paymentsFormatted;
          this.fullData = result.userRows;
          this.total = result.resultsCount;
        })
        .catch((err) => {
          if (err == 401) {
            this.logout();
          }
        });
    },

    setFilters(data) {
      data.fromDate = this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[0]);
      data.toDate = this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[1]);

      if (data.sortBy.column === "id") {
        data.sortBy = {
          column: "operationDate",
          order: "desc",
        };
      }

      data.countryIds = this.filtersToSend.countryOfDomicile || [];
      data.operations = this.filtersToSend.operation || [];
      data.filter = { 
        billingPeriods: this.filtersToSend.billingPeriod,
        paidPlans: this.filtersToSend.paidPlan,
      };
    },

    startOfMonth() {
      const date = new Date();
      date.setDate(1);
      return date.toISOString().split("T")[0];
    },

    formatDate(dateString) {
      // Handle undefined or null
      if (!dateString) {
        return '';
      }

      // If it's already in YYYY-MM-DD format, return as is
      if (typeof dateString === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        return dateString;
      }
      
      // If it's a Date object or ISO string, convert to YYYY-MM-DD
      try {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      } catch (e) {
        console.error('Invalid date:', dateString);
        return '';
      }
    },
    
    today() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },

    convertToPeriod($billingPeriod) {
      if ($billingPeriod === 0) return "Monthly";
      else if ($billingPeriod === 1) return "Annualy";
    },

    convertToPlan($plan) {
      if ($plan === 0) return "Explore";
      else if ($plan === 1) return "Learn";
      else if ($plan === 2) return "Grow";
    },

    formatDecimals(value) {
      let rounded = Math.round(value * 20) / 20;
      let formatted = rounded.toFixed(2);
      return formatted;
    },

    exportExcel: async function () {
      let data = {
        fromDate: this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[0]),
        toDate: this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[1]),
        countryIds: this.filtersToSend.countryOfDomicile || [],
        operations: this.filtersToSend.operation || [],
        itemsPerPage: 1000000,
        filter: {
          billingPeriods: this.filtersToSend.billingPeriod,
          paidPlans: this.filtersToSend.paidPlan,
        }
      };

      let dataToExport = null;

      try {
        const res = await this.getSubscribersPayments(data);
        dataToExport = res.userRows;
      } catch (err) {
        if (err === 401) {
          this.logout();
          return;
        }
        console.error(err);
        return;
      }

      // Create Excel data
      const excelData = dataToExport.map((row) => {
        return {
          "Subscription ID": row.subscriptionId,
          "First Name": row.firstName,
          "Last Name": row.lastName,
          "Email": row.email,
          "Country of Domicile": row.countryOfDomicile,
          "Paid Plan": this.convertToPlan(row.paidPlan),
          "Operation": row.operation,
          "Operation Date": row.operationDate,
          "Currency Code": row.currencyCode,
          "Amount Ex VAT": row.amountExVAT,
          "Amount Inc VAT": row.amountIncVAT,
          "Amount VAT": row.amountVAT,
          "VAT Rate": row.vatRate,
          "Billing Period": this.convertToPeriod(row.billingPeriod),
          "Card Brand": row.cardBrand,
          "Card Expiry Date": row.cardExpiryDate,
          "Card Number": row.cardNumber,
          "Payment Log ID": row.paymentLogId,
          "Payrexx Fee": row.payrexxFee,
        };
      });

      // Create a worksheet
      const ws = utils.json_to_sheet(excelData);

      // Create a new workbook and add the worksheet to it
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      // Format the date for the file name
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const fileName = `Subscribers_Payments_${formattedDate}.xlsx`;

      // Write the workbook to a file
      writeFile(wb, fileName);
    },
  },
};
</script>

<style scoped>
.field {
  margin: 15px 0 30px;
  display: flex;
  align-items: center;
}

.date-filter {
  display: flex;
  align-items: center;
}

.field label:not(.custom-check) {
  color: #44444f;
  font-size: 16px;
  font-weight: 500;
  width: 100px;
}

.field input,
.field :deep(input) {
  width: 200px;
  height: auto;
  box-shadow: none;
  line-height: initial;
  background: #fafafb;
  border: 1px solid #44444f;
  border-radius: 10px;
  padding: 12px 15px;
  margin: 0 10px;
  outline: none;
  font-size: 16px;
  color: #171725;
}

.field input:focus,
.field :deep(input:focus) {
  border-color: #3c6e71;
}

.field button {
  background: #3c6e71;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  width: 100px;
  height: 51px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
}

.field button:hover {
  background: #fff;
  color: #3c6e71;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.export-btn {
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 0 25px auto;
}

.export-btn:hover {
  background: #fff;
  color: #3c6e71;
}

.country-filter {
  display: flex;
  align-items: center;
}

.country-filter label {
  font-size: 18px;
  margin-right: 10px;
}

.country-filter select {
  width: 210px;
  border: none;
  border-radius: 20px;
  padding: 9px 15px;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /*background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 0.75rem center/8px 10px;*/
  background-color: white;
}

.country-filter .filter-btn {
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.country-filter .filter-btn:hover {
  background: #fff;
  color: #3c6e71;
}
</style>
